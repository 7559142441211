import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ActividadForm from './ActividadForm';
import VerActividad from './VerActividad';
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarActividades.php`;

function Actividades ({ idMC, rol }){
    const [actividades, setActividades] = useState([]);
		const [mostrarActividad, setMostrarActividad] = useState('');
    const [verFormularioActividad, setVerFormularioActividad] = useState(false);
    const [datoActividad, setDatoActividad] = useState({
        titulo: '',
        desarrollo: '',
        presentacion: '',
        fechaEntrega: '',
        tipoTrabajo: 'grupal',
        idTrabajo: '',
        id_curso: idMC,
        modo: 'Nuevo',
    });

    useEffect(() => {
        fetchActividades();
    }, []);

    const fetchActividades = async () => {
        try {
            const response = await axios.get(`${URL}?id_curso=${idMC}`);
            setActividades(response.data.actividades);
        } catch (error) {
            console.error('Error fetching actividades:', error);
        }
    };

    const handleNuevaActividad = () => {
        setVerFormularioActividad(true);
    };

    
		const handleSubmit = async (e) => {
			e.preventDefault(); // Prevenir el comportamiento por defecto del formulario
	
			const formData = new FormData(); // Usamos FormData para manejar archivos
			formData.append("id_curso", datoActividad.id_curso);
			
			formData.append("titulo", datoActividad.titulo);
			formData.append("desarrollo", datoActividad.desarrollo);
			formData.append("forma_presentacion", datoActividad.presentacion);
			formData.append("tipo_trabajo", datoActividad.tipoTrabajo);
			formData.append("fecha_entrega", datoActividad.fechaEntrega);
			formData.append("material", datoActividad.material || ''); // En caso de que no haya material
			formData.append("adjunto", datoActividad.archivo || null); // En caso de que haya archivo adjunto
			formData.append("creado_por", 1); // Ajustar según corresponda
			formData.append("fecha_creacion", new Date().toISOString());
	
			try {
					const response = await axios.post(URL, formData, {
							headers: {
									"Content-Type": "multipart/form-data"
							}
					});
					if (response.data.success) {
							console.log("Actividad guardada exitosamente");
							fetchActividades(); // Refrescar la lista de actividades
							setVerFormularioActividad(false); // Cerrar el formulario
					} else {
							console.error("Error al guardar la actividad", response.data);
					}
			} catch (error) {
					console.error("Error al enviar la solicitud", error);
			}
	};

    const verActividad=(actividad)=>{
			console.log(actividad);
			setMostrarActividad(actividad)
    }
    const verEntregasEstudiantes=(id)=>{

    }
    const editarActividad=(id)=>{

    }
    const elimina=(id)=>{

    }
    
    return (
        <div id={`tarea_clase_${idMC}`} className="tarea" >
        {(!verFormularioActividad && mostrarActividad ==='') ?
          <div>
						<h3 className=' mt-3'>Actividades</h3>
						{rol==6 &&
						<button type="button" className="btn btn-dark btn-sm" onClick={()=>handleNuevaActividad()}>
							<i className="fa-regular fa-square-plus me-1" ></i> actividad
            </button>
						}
						<table width="100%" className="table table-sm">
                <thead>
                    <tr>
                        <th width="1%"></th>
                        <th width="20%">Título</th>
                        <th width="25%">Desarrollo</th>
                        <th width="16%">Fecha de entrega</th>
                        <th width="10%">Trabajo</th>
                        <th width="28%"></th>
                    </tr>
                </thead>
                <tbody>
                    {actividades.length > 0 ? actividades.map((actividad, index) => (
                        <tr key={index} id={`fila_trabajo_${actividad.id_trabajo}`}>
                            <td className="small">{actividad.adjunto && <span className='icon-paperclip'></span>}</td>
                            <td className="small">{actividad.titulo}</td>
                            <td className="small">{`${actividad.desarrollo.substring(0, 150)}...`}</td>
                            <td className="small">{actividad.fecha_entrega}</td>
                            <td className="small">{actividad.tipo_trabajo}</td>
                            <td className="small">
                                <button className="btn btn-outline-success btn-sm me-1" onClick={() => verActividad(actividad)}><i className="fa-solid fa-eye"></i></button>
                                {rol==6 &&
																<>
																	<button className="btn btn-outline-dark btn-sm me-1" onClick={() => verEntregasEstudiantes(actividad.id_trabajo)}> <i className="fa-solid fa-graduation-cap"></i> Entregas</button>
                                	<button className="btn btn-outline-warning btn-sm me-1" onClick={() => editarActividad(actividad.id_trabajo)}><i className="fa-regular fa-pen-to-square"></i></button>
                                	<button className="btn btn-outline-danger btn-sm me-1" onClick={() => elimina(actividad.id_trabajo, 'trabajo')}><i className="fa-regular fa-trash-can"></i></button>
																</>
																}
														</td>
                        </tr>
                    )) : (
                        <tr><td colSpan="6">No se registran actividades en la clase</td></tr>
                    )}
                </tbody>
            </table>
					</div>  
        :
				<>
				{verFormularioActividad &&
					<ActividadForm 
						datoActividad={datoActividad}
						setDatoActividad={setDatoActividad}
						handleSubmit={handleSubmit} 
						setVerFormularioActividad={setVerFormularioActividad} 
					/>
				}
				{mostrarActividad!=='' && <VerActividad actividad={mostrarActividad} setMostrarActividad={setMostrarActividad} />}
				</>
				}    
        </div>
    );
};



export default Actividades;
