import axios from 'axios';
import { useEffect, useState } from 'react';
import CONFIG from '../../config';

const URL = `${CONFIG.API_URL}/operarCursos.php`;

function MostrarEncabezadoInstancias({curso}) {
    const [instancias, setInstancias] = useState([]);

    useEffect(() => {
        obtenerInstancias(curso);             
    }, [curso]);

    const obtenerInstancias =(curso)=>{
        axios.get(`${URL}?curso=${curso}`)
        .then(res => {
            console.log(res.data.instancias);
            if (!res.data.error) { 
                setInstancias(res.data.instancias);
            } else {
                setInstancias([]);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (  
        <>
        {instancias.length>0 && instancias.map((i) => (
            <div key={i.id}>{i.nombre_instancia}</div>
        ))}
        </>
    );
}

export default MostrarEncabezadoInstancias;