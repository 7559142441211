import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { show_alerta } from '../../funciones.js';
import Espera from '../Espera';
import './Principal.css';

import CONFIG from '../../config';

const URL_LISTAR = `${CONFIG.API_URL}/listarUsuarios.php`;
const URL  = `${CONFIG.API_URL}/operarTablaUsuario.php`;
const URL_cursos  = `${CONFIG.API_URL}/operarCursos.php`;
const URL_CALENDARIO = `${CONFIG.API_URL}/operarCalendario.php`;

function PrincipalEstudiante({rol, configuracion}) {
    const [perfil, setPerfil] = useState({
        id:null,
        nombre:"",
        apellido:"",
        apodo:"",
        imagen_perfil:"",
        fecnac:"",
        email:"",
        genero:"",
        telefono:"",
        calle:"",
        numero:"",
        piso:"",
        depto:"",
        ciudad:"",
        provincia:"",
        modo:"actualizar-perfil"
    });
    const [espera, setEspera] = useState(false);
    const [cursos, setCursos] = useState([]);
    const [eventos, setEventos] = useState([]);
    const userId =localStorage.getItem('loggeduserId');
    const defaultFilePerfil='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png';

    const data= {
        'id_usuario' :  userId,
        'modo': 'buscarPerfilUsuario'
    }

    useEffect(()=>{   
        obtenerDatos();
        obtenerCursos();
        obtenerEventos();
      },[])
    
    const obtenerDatos=()=>{
        axios.post(URL_LISTAR, data)
        .then(res =>{
            if(!res.data.error){ 
                //Sconsole.log(res.data);
                setPerfil(res.data);
            }else{
                setPerfil(null);
            }
        })
        .catch(err=>{
            console.log(err);
        })
    }

    const obtenerEdad=(dateString)=> {
        let hoy = new Date()
        let fechaNacimiento = new Date(dateString)
        let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
        let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
        if (
          diferenciaMeses < 0 ||
          (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
        ) {
          edad--
        }
        return edad
    }

    const obtenerCursos =()=>{
        const data= {
            'id_usuario' : userId,
            'modo': 'buscarCursosUsuario',
            'llama':rol
        }
        // console.log(data);
        setEspera(true);
        axios.post(URL_cursos , data)
        .then(res =>{
            console.log('valor: '+JSON.stringify(res.data));
            (!res.data.error)?setCursos(res.data):setCursos([]);
            setEspera(false);
        })
        .catch(err=>{
            console.log(err);
        })
    }
    const mostrarNombre = (orden)=>{
        let nombre="";
        switch(orden){
          case "S3":
            nombre='Sala de 3';
          break
          case "S4":
            nombre='Sala de 4';
          break
          case "S5":
            nombre='Sala de 5';
          break
          case "In":
            nombre='Espacio Institucional';
          break
          default:
            nombre=orden+'°';
        }
        return nombre
      };


      const obtenerEventos = async () => {
        try {
            const res = await axios.get(URL_CALENDARIO);
            if (Array.isArray(res.data)) {
                // Aplicar filtros según el rol
                const eventosFiltrados = res.data.filter(evento => {
                    const esPropio = evento.creada_por == userId;
    
                    let filtroPorRol = false;
                    switch (rol) {
                        case 1:
                        case 2:
                            filtroPorRol = ['todos', 'todosA', 'todosD', 'todosE','todosT','todosM'].includes(evento.tipo_recordatorio);
                            break;
                        case 3:
                        case 4:
                            filtroPorRol = ['todos', 'todosA'].includes(evento.tipo_recordatorio);
                            break;
                        case 5:
                        case 6:
                        case 9:
                            filtroPorRol = ['todos', 'todosD'].includes(evento.tipo_recordatorio);
                            break;
                        case 7:
                            filtroPorRol = ['todos', 'todosE'].includes(evento.tipo_recordatorio);
                            break;
                        case 8:
                            filtroPorRol = ['todos', 'todosT'].includes(evento.tipo_recordatorio);
                            break;
                        case 12:
                            filtroPorRol = ['todos', 'todosM'].includes(evento.tipo_recordatorio);
                            break;
                        default:
                            filtroPorRol = false;
                            break;
                    }
    
                    return esPropio || filtroPorRol;
                });
    
                setEventos(eventosFiltrados);
            } else {
                setEventos([]);
                console.error('La respuesta de la API no es un array:', res.data);
            }
        } catch (err) {
            console.error('Error al obtener eventos:', err);
        }
    };

    const eventosHoy = () => {
        const fechaActual = new Date();
        fechaActual.setHours(0, 0, 0, 0); // Asegúrate de comparar solo las fechas

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            fechaEvento.setHours(0, 0, 0, 0);
            return fechaEvento.getTime() === fechaActual.getTime();
        });
    };

    const proximosEventos = (dias_proximos) => {
        const fechaActual = new Date();
        const fechaLimite = new Date();
        fechaLimite.setDate(fechaActual.getDate() + dias_proximos);

        return eventos.filter((evento) => {
            const fechaEvento = new Date(evento.fecha);
            return fechaEvento >= fechaActual && fechaEvento <= fechaLimite;
        });
    };


    return (    
        <div>
            <div className='principal-estudiante-card-superior' style={{backgroundColor: configuracion.color_principal }}>
              <div className='row'>
                <div className='col-6'>
									<div className='d-flex'>
                    <div className=''>
                        <img id="imagen" className='imagen-circular' src={perfil.imagen_perfil==null ? `${defaultFilePerfil}` : `${CONFIG.API_URL}/${perfil.imagen_perfil}`} />
                        <div className=' pie-foto'>{perfil.apodo} ({obtenerEdad(perfil.fecnac)})</div>
                    </div>
                    <div className='contenedor-principal-nombre' >
                        <h2 className='principal-nombre'>{perfil.nombre} <br></br> {perfil.apellido} </h2>
                    </div>
                	</div>
                </div>
                <div className='col-6'>
									<div className='d-flex justify-content-end'>
                    <div className=' d-flex justify-conten-end text-white'>Valoraciones</div>
                  </div>                        
                </div>
              </div>    
            </div>
            <div className='row'>
                <div className='col-12 col-sm-8'>
                    {!espera ? 
                    <>
                    {cursos.length==0 ?"No exiten curso/s"
                    :<div className='pt-4 pb-4'>
                        <table className='table table-dark table-striped-columns table-hover'>
                            <thead>
                                <tr>
                                    <th>curso</th>
                                    <th>docente</th>
                                </tr>
                            </thead>
                            <tbody>
                            {cursos.map((c, index)=>(
                                <tr key={index}>
                                    <td>{mostrarNombre(c.orden)} - {c.nombre_espacio} {c.denominacion}</td>
                                    <td>{c.denominacion}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    }
                    </>
                    :<div className='container m-3'><Espera visible={espera} /></div>}

                </div>
                <div className='col-12 col-sm-4'>
                    <h3>Area comunicacion</h3>
                    <div className=''>
                        <div className='card mb-3'>
                            <div className='card-body'>
                                <div className='card-title'>
                                    <h5>Eventos de Hoy:</h5>
                                </div>
                                {eventosHoy().map((evento) => (
                                <div key={evento.id_evento}>
                                        <strong>{evento.evento}</strong> - {evento.hora_desde} a {evento.hora_hasta}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='card mb-3'>
                            <div className='card-body'>
                                <div className='card-title'>
                                    <h5>Próximos eventos <small>(15 días)</small>:</h5 >
                                </div>
                                {proximosEventos(15).map((evento) => (
                                    <div key={evento.id_evento}>
                                        <strong>{evento.evento}</strong> - {new Date(evento.fecha).toLocaleDateString()} ({evento.hora_desde} - {evento.hora_hasta})
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default PrincipalEstudiante;