import './css/quill.snow.css';
import './css/Aulas.css';
import ClaseForm from './ClaseForm';
import ClaseMateriales from './ClaseMateriales';
import ClaseActividades from './ClaseActividades';

import { obtenerTemas } from '../../servicios/forumService';

import Foro from '../mensajes/Foro.js';
import { useState, useEffect } from 'react';

function Clase({rol, clases, clase, enviarSolicitud, editarClase, nuevaClase, setEditaMaterial, editaMaterial, setEditaActividad, editaActividad, verAreaForos}) {
    const [cantMateriales, setCantMateriales] = useState(0);
    const [cantActividades, setCantActividades] = useState(0);
    const [cantTemasForos, setCantTemasForos] = useState(0);

		const [temas, setTemas] = useState([]);


		useEffect(() => {
			
			fetchTemas();
		}, [rol, clases, clase, enviarSolicitud, editarClase, nuevaClase, setEditaMaterial, editaMaterial, setEditaActividad, editaActividad, verAreaForos]);

		const fetchTemas = async () => {
			const data = await obtenerTemas(clase.id);
			setTemas(data);
			setCantTemasForos(data.length);
			//console.log("useeffect:"+data.length);
			//console.log("useeffect idCurso: "+ clase.id);

		};

    return (    
        <>
            <div>
                {(editarClase || nuevaClase)?
                <div className="hoja-form">
                    <h5>{editarClase ? "Edita ":"Nueva"} clase</h5>
                    <ClaseForm clase={clase} enviarSolicitud={enviarSolicitud}  />
                </div>
                :
                <>
                { clases.length==0 ? 'SIN CLASES CLASE':
                	verAreaForos ?  <Foro  rol={rol} clase={clase} temas={temas} setTemas={setTemas} fetchTemas={fetchTemas}/>
										:
                    <div className="hoja mb-4">
                        <div className="clase-titulo">
                            Titulo: {clase.tema}
                            <div className="elementos_clase">
                                <i className="fa-solid fa-book m-1"></i> {cantMateriales}
                                <i className="fa-solid fa-briefcase m-1"></i> {cantActividades}
                                <i className="fa-regular fa-message me-1"></i> {cantTemasForos}
                            </div>
                        </div>
                        {rol==7 && cantTemasForos>0 &&<Foro  rol={rol} clase={clase} temas={temas} setTemas={setTemas} fetchTemas={fetchTemas}/>}
                        <div className='clase-material'>
                            <div className='clase-material-titulo'>
                                <div>Recursos</div>
                                <div className='clase-material-circulo'><i className="fa-solid fa-book"></i></div>
                            </div>
                            {(rol==6 || rol==9 || rol==11) ? <span className={`clase-boton-material ${editaMaterial?'clase-boton-material-cancel':''}`} onClick={()=>setEditaMaterial(!editaMaterial)}>{editaMaterial?'Cancelar edicion':<><i className="fa-solid fa-pencil"></i></>}</span> :''}
                            <ClaseMateriales setCantMateriales={setCantMateriales} enviarSolicitud={enviarSolicitud} id_clase={clase.id} setEditaMaterial={setEditaMaterial} editaMaterial={editaMaterial} />
                        </div>
                        <div className="presentacion informacion" dangerouslySetInnerHTML={{ __html:clase.presentacion}}></div>
                        <div className="desarrollo informacion" dangerouslySetInnerHTML={{ __html:clase.desarrollo}}></div>
                        <div className="cierre informacion" dangerouslySetInnerHTML={{ __html:clase.cierre}}></div>
                        <div className='clase-actividad'>
                            <div className='clase-actividad-titulo'>
                                <div>Actividades</div>
                                <div className='clase-actividad-circulo'><i className="fa-solid fa-briefcase"></i></div>
                            </div>
                            {(rol==6 || rol==9 || rol==11) ? <span className={`clase-boton-actividad ${editaActividad?'clase-boton-actividad-cancel':''}`} onClick={()=>setEditaActividad(!editaActividad)}>{editaActividad?'Cancelar edicion Actividades':<><i className="fa-solid fa-pencil"></i></>}</span> :''}
                           
                            <ClaseActividades setCantActividades={setCantActividades} enviarSolicitud={enviarSolicitud} id_curso={clase.id_curso} id_clase={clase.id} setEditaActividad={setEditaActividad} editaActividad={editaActividad}/>
                        </div>
                    </div>


                }
                </>
                }
            </div>
        </>
     );
}

export default Clase;