import './css/estudiantes.css';
import axios from 'axios';
import { useEffect, useState } from 'react';
import Espera from '../Espera';
import PerfilLogo from '../usuarios/PerfilLogo.js';
import { useNavigate } from "react-router-dom";
import CONFIG from '../../config';
import MostrarEncabezadoInstancias from './MostrarEncabezadoInstancias.js';

const URL = `${CONFIG.API_URL}/operarEstudiantes.php`;

function Estudiantes({ acceder, rol }) {
    const [estudiantes, setEstudiantes] = useState([]);
    const [visible, setVisible] = useState(false);
    const [vista, setVista] = useState('calificaciones'); // Estado para controlar la vista activa
    const navigate = useNavigate();
   
    const loggeduserCurso = localStorage.getItem('loggeduserCurso');
    const loggeduserCursoGrupo = localStorage.getItem('loggeduserCursoGrupo');

    useEffect(() => {
        if (acceder) {
            if (rol === null) {
                navigate("/");
            } else {  
                const data = {
                    'id_curso': loggeduserCurso,
                    'id_grupo': loggeduserCursoGrupo,
                    'modo': 'buscarEstudiantesCurso'
                };
                obtenerEstudiantes(data);
            }
        } else {
            localStorage.clear();
            navigate('/');
        }                   
    }, [acceder, rol, navigate, loggeduserCurso, loggeduserCursoGrupo]);

    const obtenerEstudiantes = (dato) => {
        setVisible(true);
        axios.post(URL, dato)
        .then(res => {
            if (!res.data.error) { 
                setEstudiantes(res.data);
            } else {
                setEstudiantes([]);
            }
            setVisible(false);
        })
        .catch(err => {
            console.log(err);
        });
    }

    // Función para manejar el cambio de vista
    const handleVistaChange = (nuevaVista) => {
        setVista(nuevaVista);
    }

    return (
        <div className="container-principal">
            <small>curso_grupo: #{loggeduserCursoGrupo}</small>
            <h3 className='estudiantes-titulo'>Estudiantes en el curso {rol == 6 && 
                <button type='button' className='btn btn-outline-info btn-sm'>
                    + <i className="fa-solid fa-graduation-cap"></i>
                </button>
            }</h3>
            
            {rol == 6 &&
                <div className='row'>
                    <div className="col-12">
                        <ul className="nav nav-tabs justify-content-end">
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'calificaciones' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('calificaciones')}
                                >
                                    Calificaciones
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'asistencia' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('asistencia')}
                                >
                                    Asistencia
                                </button>
                            </li>
                            <li className="nav-item">
                                <button 
                                    className={`nav-link ${vista === 'actividades' ? 'active' : ''}`} 
                                    onClick={() => handleVistaChange('actividades')}
                                >
                                    Actividades
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            }

            {!visible ? 
                <>
                    {estudiantes.length > 0 ? (
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>estudiante</th>
                                    <th>{vista ==='calificaciones'&&<MostrarEncabezadoInstancias curso={loggeduserCurso}/>}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {estudiantes.map((e) => (
                                    <tr key={e.id}>
                                        <td className='col-sm-5 col-12'>
                                            <PerfilLogo usuario={e} version="extendida" />
                                        </td>
                                        <td className='col-sm-4 col-12'>
                                            {/* Renderizar contenido basado en la vista seleccionada */}
                                            {vista === 'calificaciones' && <div><button type='button' className='btn btn-info btn-sm m-1'> Calificar</button></div>}
                                            {vista === 'asistencia' && <div>Mostrar asistencia aquí</div>}
                                            {vista === 'actividades' && <div>Mostrar actividades aquí</div>}
                                        </td>
                                        <td className='col-sm-3 col-12 estudiante-botonera'>
                                            {rol == 6 &&
                                            <>
                                                <button type='button' className='btn btn-warning btn-sm'>- <i className="fa-solid fa-graduation-cap"></i></button>
                                            </>
                                            }
                                        </td>
                                    </tr>  
                                ))}
                            </tbody>
                        </table>
                    ) : <div>Sin estudiantes</div>}
                    
                    
                </> 
                : <Espera />
            }
        </div>
    );
}

export default Estudiantes;
