import './css/Cursos.css';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { useEffect , useState} from "react";
import Espera from '../Espera';
import { QRCodeSVG } from 'qrcode.react'; // Importa la biblioteca QR Code
import CONFIG from '../../config';

const URL  = `${CONFIG.API_URL}/operarCursos.php`;
const URL_PLATAFORMA =`${CONFIG.BASE_URL}`;

function MiCurso({acceder, rol}) {
    const { idMC } = useParams();
    const navigate = useNavigate();
    const loggeduserCursoGrupoO =JSON.parse(localStorage.getItem('loggeduserCursoGrupoO'));
    const loggeduserClasesCurso =localStorage.getItem('loggeduserClasesCurso');
    const [curso, setCurso] = useState(loggeduserCursoGrupoO);
    const [clases, setClases] = useState([]);
    const [espera, setEspera] = useState(false);

    const loggeduserCurso =localStorage.getItem('loggeduserCurso');
    const loggeduserCursoGrupo =localStorage.getItem('loggeduserCursoGrupo');
    
    
    const data= {
        'id_curso' : idMC,
        'id_grupo' : loggeduserCursoGrupo,
        'modo': 'buscarClasesCursoUsuario'
    }
    
    useEffect(() => {
        //console.log(acceder)
        if(acceder){
            if((rol===null) || (rol<6)){
                navigate("/");
            }else{
                //si no tiene codigo inscripcion lo generao
                curso.codigo_inscripcion === ''&& generarQRCode(curso.id_curso_grupo);
                if(idMC===loggeduserCurso){
                    //console.log('loggeduserClasesCurso: '+loggeduserClasesCurso);
                    if((!loggeduserClasesCurso) || (loggeduserClasesCurso==null)){ //verifico que no tenga las calses almacenadas asi no repito la API
                       // console.log('entro en if, data:'+JSON.stringify(data));
                        buscaClasesCurso(data);
                    }else{
                       // console.log('no entro en if');
                        const parse = JSON.parse(loggeduserClasesCurso);
                        parse.sort((a, b) => b.id - a.id);
                        localStorage.setItem('loggeduserClasesCurso',  JSON.stringify(parse));
                        setClases(parse);
                    }
                }else{
                    navigate(`/principal`);
                }
            }
        }else{
            localStorage.clear();
            navigate('/');
        }
        //console.log("cursoooo:"+clases);
    }, []);
    
    const buscaClasesCurso =  (d) =>{
        setEspera(true);
        axios.post(URL, d)
        .then(res =>{
          //  console.log("cursoooo:"+JSON.stringify(res.data));
            if(!res.data.error){   
                //setCurso(res.data.curso);
                setClases(res.data.sort((a, b) => b.id - a.id))
                localStorage.setItem('loggeduserClasesCurso',  JSON.stringify(res.data));
                
               //console.log("cursos:"+curso);
            }else{
               // setCurso([]);
                setClases([]);
            }
            setEspera(false);           
        })
        .catch(err=>{
            console.log(err);
        })   
    }

    const generarQRCode = async (id_curso_grupo) => {
        const data = {
            id_curso_grupo: id_curso_grupo,
            modo:'generarCodigoInscripcion'
        };
        try {
            const res = await axios.post(URL, data)
            const [tipo, msj, codigo] = res.data;
            if (tipo === 'success') {
             //   obtenerDatosCursos();
             //   setQrCode(codigo)
             curso.codigo_inscripcion=codigo;
             console.log(curso.codigo_inscripcion)
            }
        } catch (err) {
            //show_alerta('Error en la solicitud', 'error');
        }
    };    
    return ( 
        <div className="container-principal"> 
            <div className='contenedor-micurso'>
                <div className="portada-curso">
                    <img src={curso.imagen}/>
                    <img src={curso.imagen === '' ? `${CONFIG.API_URL}/${curso.caratula_formacion}` : curso.imagen} className="card-img-top" alt='imagen-decorativa' />
                    <h3>{curso.tipo_formacion!=7?`${curso.orden}° ${curso.nombre}`:''}{curso.nombre}</h3>
                    <h4>Grupo {curso.denominacion}</h4>
                    <h5>id.: {curso.id}-{curso.id_curso_grupo}</h5>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {rol==6 &&
                        <div className="accordion my-2" id="accordionExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                    <button className="accordion-button text-center" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                        Codigo de inscripcion
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse " data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <div className='row'>
                                            <div className='col-12 col-sm-3'>
                                                <div className='card mt-1'>
                                                    <div className="card-body d-flex justify-content-center">
                                                        <QRCodeSVG value={`${URL_PLATAFORMA}inscripcion/${curso.codigo_inscripcion}`} size={128} />
                                                    </div>   
                                                    <div className='d-flex justify-content-center'>
                                                        <h3>{curso.codigo_inscripcion}</h3>
                                                    </div>                     
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-9'>
                                            <strong>Este código de inscripción alfanumérico</strong> será requerido cuando se realiza la inscripción a la plataforma de manera auto asistida.
                                                En tanto <strong>el código QR </strong> podrá ser escaneado con un dispositivo móvil para dirigirse directamente al formulario de registro con el código ya precargado en el mismo.
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {rol==1 && <button type='button' className='btn-csi2' onClick={() => navigate(`/MC/${curso.id}/IA/${curso.id_curso_grupo}`)}><i className="fa-solid fa-brain"></i> Generador de Plan de Curso asistido con IA</button>}
                       <button className="btn-csi2" type="button" onClick={() => navigate(`/MC/${curso.id}/P/${curso.id_curso_grupo}`)}><i className="fa-solid fa-list-check"></i> Planificacion</button>
                    </div>
                </div>
                
                <div className="d-grid gap-2 mt-4">
                    {!espera ? 
                        clases.map((cl)=>(
                            <div key={cl.id} className='card p-2 mb-2'>
                                <div className='row'>
                                    <div className='col-sm-1 col-12'>
                                        <h1 className='text-info text-center hacer-link' onClick={()=>navigate(`/MC/${curso.id}/c`)}><i className="fa-regular fa-file-lines"></i></h1>
                                        <h5 className='text-info text-center'>clase</h5>
                                    </div>
                                    <div className='col-sm-11 col-12'>
                                        <h4 className="hacer-link" onClick={()=>navigate(`/MC/${curso.id}/c`)}>{cl.titulo_corto}:{cl.tema} </h4>
                                        <hr/>
                                        <div>{cl.fecha}</div>
                                    </div>
                                </div>
                            </div>
                        ))
                        : <div className='container m-3'><Espera visible={espera} /></div>
                        }                
                </div>
            </div>
        </div> 
    );
}

export default MiCurso;