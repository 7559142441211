// src/services/cursoService.js
import axios from 'axios';
import CONFIG from '../config'

const URL = `${CONFIG.API_URL}/operarCursos.php`;

export const buscarCursoPorId = async (id_curso_grupo) => {
    try {
      const response = await axios.post(URL, {'id_curso_grupo': id_curso_grupo,'modo': 'buscarCursoID'});
      return response
    } catch (error) {
      console.error('Error al obtener el curso', error);
      return null;
    }
  };
  
  export const buscarEstudiantesPorCurso = async (id_curso_grupo) => {
    try {
      const response = await axios.post(URL, {'id_curso_grupo': id_curso_grupo, 'modo': 'buscarEstudiantesCurso'});
      return response
    } catch (error) {
      console.error('Error al obtener el curso', error);
      return null;
    }
  };
  export const buscarDocentesPorCurso = async (id_curso_grupo) => {
    try {
      const response = await axios.post(URL, {'id_curso_grupo': id_curso_grupo, 'modo': 'buscarDocentesEnCurso'});
      return response
    } catch (error) {
      console.error('Error al obtener el curso', error);
      return null;
    }
  };

  export const asignarEstudianteAlCurso = async (id_curso_grupo, idEstudiante) => {
    try {
      const response = await axios.post(URL, { 'id_curso_grupo': id_curso_grupo, 'modo': 'asignaEstudiante', 'idEstudiante': idEstudiante });
      return response
    } catch (error) {
      console.error('Error al asignar el aestudiante al curso', error);
      return null;
    }
  };
  export const quitarEstudianteAlCurso = async (id_estudiante_curso) => {
    try {
      //console.log('id antes de ir al back:'+ id_estudiante_curso)
      const response = await axios({ method: 'DELETE', url: URL, data: {'id': id_estudiante_curso, 'tabla':'curso_estudiante'} });
      
      return response
    } catch (error) {
      console.error('Error al quitar el estudiante del curso', error);
      return null;
    }
  };