import './css/Aulas.css';
import React, { useEffect, useState } from 'react';


function ClaseActividadesForm({setEditaActividad, id_clase, actividadesCurso, enviarSolicitud}) {
    const [actividadesFijar, setActividadesFijar] = useState([]);
    const [actividad, setActividad] = useState("");
    useEffect(() => {
        setActividadesFijar(actividadesCurso.filter((a) =>{
            if( a.id_clase===null ){
                return true;
            }
            return false;
        }))   
    }, []);
    const handleSubmit = (e) =>{
        e.preventDefault();
       // console.log(actividad);
        const formData = new FormData();
        formData.append('nuevo', 'ActividadPoner');
        formData.append('id_trabajo', actividad);
        formData.append('id_clase', id_clase);
        formData.append('id_usuario', '0');
        enviarSolicitud("POST",formData);
        setEditaActividad(false);
    }
    return (    
        <>
        <form className="my-3" onSubmit={handleSubmit}>
            <div className="row">
                <div className="col-10">
                    <select className="form-select" onChange={(e)=>setActividad(e.target.value)}>
                        <option>Selecciona una actividad del laboratorio</option>
                      {actividadesFijar.map((a)=>(
                        <option key={a.id} defaultValue={a.id} value={a.id}>{a.titulo}</option>
                      ))}
                    </select>
                </div>
                <div className="col-2">
                    <button type="submit" className="btn btn-sm btn-success"><i className="fa-solid fa-thumbtack"></i> fijar</button>
                </div>
            </div>  
        </form>        
        </>
     );
}

export default ClaseActividadesForm;    